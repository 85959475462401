.layout header.header {
  transition: opacity 0.5s;
  background: #fff;
  padding: 0;
}

.headerContainer {
  width: 1024px;
  max-width: calc(100% - 48px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

p {
  margin: 0;
}

.logo {
  height: 64px;
  line-height: 64px;

  h1 {
    font-size: 18px;
    display: inline;
    margin-left: 8px;
  }

  img {
    float: left;
    width: 40px;
    height: 64px;
    line-height: 64px;
  }

  span {
    font-size: 16px;
    height: 64px;
    line-height: 64px;
  }
}

.content {
  width: 1024px;
  max-width: calc(100% - 48px);
  margin: 20px auto;
  padding: 24px;
  border-radius: 4px;
  background: #fff;
}

:global(.paragraph) {
  h5 {
    font-size: 16px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;

    &,
    th,
    td {
      border: 1px solid #e8e8e8;
    }

    thead {
      th,
      td {
        background: #fafafa;
      }
    }

    th,
    td {
      padding: 8px 12px;
    }
  }

  p {
    margin-bottom: 15px;
  }

  ul,
  ol {
    padding: 0 15px 15px 15px;
    margin-left: 15px;
  }

  ul {
    list-style-type: disc;
    padding-bottom: 0;
  }

  ol {
    list-style-type: decimal;
    padding-bottom: 0;
  }

  blockquote {
    color: #888;
    border-left: 3px solid #ddd;
    padding-left: 1em;
  }

  img {
    max-width: 100%;
  }
}
