@import "~antd/dist/reset.css";

body {
  margin: 0;
  padding: 0;
  font-family: Avenir, sans-serif;
  line-height: 1.667;
}

p {
  margin: 0;
}
